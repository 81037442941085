<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register fiveitems">
              <template v-for="(item, index) in itemsStep">
                <li
                  :key="index"
                  :class="item.active ? 'active' : item.finish ? 'done' : ''"
                  @click="
                    item.finish && stepdetail < 5 && changeStep(item.linkItems)
                  "
                >
                  <a href="#">
                    <span class="icons icon-checkmark"></span>
                    <span class="number">{{ item.step }}</span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <!-- <div class="contentregister register-content"> -->
          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
            <div class="form-box w-630">
              <h3 class="title-md">{{ $t("DetailStep3.title") }}</h3>

              <div class="form-group">
                <label class="light">
                  {{
                  $t("DetailStep3.title_manage_com")
                  }}
                </label>
                <div
                  class="dropdown select"
                  @click="getfocus('managercompany')"
                  @mouseleave="mouseOver('managercompany')"
                >
                  <a id="managercompany">
                    <v-autocomplete
                      v-model="managerDetail.accommodation_managed_by"
                      :items="itemsManagerCompany"
                      label
                      placeholder
                      :no-data-text="$t('SignupStep.nodata')"
                      color="#1e9ff2"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                    >
                      <template v-slot:item="data">
                        <v-list-item-content
                          @mouseenter="getfocus('managercompany')"
                          @mouseleave="mouseOver('managercompany')"
                        >
                          <v-list-item-title v-html="data.item" class="title-signup-sj"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </a>
                </div>
                <span
                  class="error-text-input"
                  v-if="!managerDetail.accommodation_managed_by"
                >{{ $t("Alert.pleasefilldata") }}</span>
              </div>

              <div class="form-group">
                <label class="light">
                  {{
                  $t("DetailStep3.title_fullname")
                  }}
                </label>
                <input
                  id="managementcompanyname"
                  type="text"
                  class="form-control"
                  name
                  placeholder
                  v-model="managerDetail.management_company_name"
                />
                <span
                  class="error-text-input"
                  v-if="!managerDetail.management_company_name"
                >{{ $t("Alert.pleasefilldata") }}</span>
              </div>

              <div class="form-group">
                <label class="light">
                  {{ $t("DetailStep3.title_alternative") }}
                  <span class="graylight">
                    {{
                    $t("DetailStep3.label_optional")
                    }}
                  </span>
                </label>
                <input
                  id="alternativename"
                  type="text"
                  class="form-control"
                  name
                  placeholder
                  v-model="managerDetail.alternative_name"
                />
              </div>

              <div class="form-group">
                <label class="light">
                  {{
                  $t("DetailStep3.title_address")
                  }}
                </label>
                <input
                  id="manageraddress"
                  type="text"
                  class="form-control"
                  name
                  placeholder
                  v-model="managerDetail.address"
                />
                <span class="error-text-input" v-if="!managerDetail.address">
                  {{
                  $t("Alert.pleasefilldata")
                  }}
                </span>
              </div>

              <div class="form-group">
                <label class="light">
                  {{ $t("DetailStep3.title_unitname") }}
                  <span class="graylight">
                    {{
                    $t("DetailStep3.label_optional")
                    }}
                  </span>
                </label>
                <input
                  id="managerunit"
                  type="text"
                  class="form-control"
                  name
                  placeholder
                  v-model="managerDetail.unit_number"
                />
              </div>

              <div class="form-group">
                <label class="light">{{ $t("DetailStep3.title_phone") }}</label>
                <vue-tel-input
                  id="contactphone"
                  name="contactphone"
                  v-model="managerDetail.phone"
                  :placeholder="parsedPlaceholder"
                  class="form-control"
                  :allCountries="itemsPhoneCountries"
                  @country-changed="changeTelCT1"
                ></vue-tel-input>
                <span class="error-text-input" v-if="!managerDetail.phone">
                  {{
                  $t("Alert.pleasefilldata")
                  }}
                </span>
              </div>

              <div class="form-group">
                <label class="light">
                  {{ $t("DetailStep3.title_telephone") }}
                  <span class="graylight">
                    {{
                    $t("DetailStep3.label_optional")
                    }}
                  </span>
                </label>
                <vue-tel-input
                  id="contactreservephone"
                  name="contactreservephone"
                  v-model="managerDetail.telephone"
                  :placeholder="parsedPlaceholder"
                  class="form-control"
                  :allCountries="itemsPhoneCountries"
                  @country-changed="changeTelCT2"
                ></vue-tel-input>
              </div>

              <div class="form-group">
                <label class="light">{{ $t("DetailStep3.title_email") }}</label>
                <input
                  id="manageremail"
                  type="text"
                  class="form-control"
                  name
                  placeholder
                  v-model="managerDetail.email"
                />
                <span class="error-text-input" v-if="!managerDetail.email">
                  {{
                  $t("Alert.pleasefilldata")
                  }}
                </span>
              </div>

              <div class="row">
                <div class="col-md-12 d-flex">
                  <span class="mt-1 mr-3">
                    {{
                    $t("DetailStep3.same_hotel_address")
                    }}
                  </span>
                  <v-btn-toggle v-model="toggle_exclusive">
                    <v-btn
                      @click="sameAddressFunc()"
                      dense
                      small
                      depressed
                      class="btn btn-outline hover-pink"
                      :class="
                        samepropertyaddress == true && 'btn-outline-active'
                      "
                    >
                      <span>{{ $t("SignupStep7.btn_yes") }}</span>
                    </v-btn>
                    <v-btn
                      @click="clearSameAddress()"
                      dense
                      small
                      depressed
                      class="btn btn-outline hover-pink no"
                      :class="
                        samepropertyaddress == false && 'btn-outline-active'
                      "
                    >
                      <span>{{ $t("SignupStep7.btn_no") }}</span>
                    </v-btn>
                  </v-btn-toggle>
                  <div class="button-answer nav address">
                    <!-- <div
                      @click="sameAddressFunc()"
                      class="btn btn-outline hover-pink"
                      :class="
                        samepropertyaddress == true && 'btn-outline-active'
                      "
                    >
                      <span>{{ $t("SignupStep7.btn_yes") }}</span>
                    </div>-->
                    <!-- <div
                      @click="clearSameAddress()"
                      class="btn btn-outline hover-pink no"
                      :class="
                        samepropertyaddress == false && 'btn-outline-active'
                      "
                    >
                      <span>{{ $t("SignupStep7.btn_no") }}</span>
                    </div>-->
                  </div>
                </div>
              </div>

              <div v-if="samepropertyaddress == false">
                <div class="form-group">
                  <label class="light">
                    {{
                    $t("DetailStep1.title_country")
                    }}
                  </label>
                  <div
                    class="dropdown select"
                    @click="getfocus('selectcountry')"
                    @mouseleave="mouseOver('selectcountry')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectcountry">
                      <v-autocomplete
                        v-model="managerDetail.country"
                        :items="itemsCountry"
                        item-text="name"
                        item-value="value"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        @mouseenter="getfocus('selectcountry')"
                        @mouseleave="mouseOver('selectcountry')"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span class="error-text-input" v-if="!managerDetail.country">
                    {{
                    $t("Alert.pleasefilldata")
                    }}
                  </span>
                </div>

                <div class="form-group">
                  <label class="light">
                    {{
                    $t("DetailStep1.title_province")
                    }}
                  </label>
                  <div
                    class="dropdown select"
                    @click="getfocus('selectprovince')"
                    @mouseleave="mouseOver('selectprovince')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectprovince">
                      <v-autocomplete
                        v-model="managerDetail.province"
                        :items="itemsProvince"
                        item-text="province"
                        item-value="province_code"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        @mouseenter="getfocus('selectprovince')"
                        @mouseleave="mouseOver('selectprovince')"
                        @change="selectProvinceFunc()"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span
                    class="error-text-input"
                    v-if="!managerDetail.province"
                  >{{ $t("Alert.pleasefilldata") }}</span>
                </div>

                <div class="form-group">
                  <label class="light">
                    {{
                    $t("DetailStep1.title_district")
                    }}
                  </label>
                  <div
                    class="dropdown select"
                    @click="getfocus('selectamphoe')"
                    @mouseleave="mouseOver('selectamphoe')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectamphoe">
                      <v-autocomplete
                        v-model="managerDetail.amphoe"
                        :items="itemsAmphoe"
                        item-text="amphoe"
                        item-value="amphoe_code"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        @mouseenter="getfocus('selectamphoe')"
                        @mouseleave="mouseOver('selectamphoe')"
                        @change="selectAmphoeFunc()"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span class="error-text-input" v-if="!managerDetail.amphoe">
                    {{
                    $t("Alert.pleasefilldata")
                    }}
                  </span>
                </div>

                <div class="form-group">
                  <label class="light">
                    {{
                    $t("DetailStep1.title_subdistrict")
                    }}
                  </label>
                  <div
                    class="dropdown select"
                    @click="getfocus('selectdistrict')"
                    @mouseleave="mouseOver('selectdistrict')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectdistrict">
                      <v-autocomplete
                        v-model="managerDetail.city"
                        :items="itemsDistrict"
                        item-text="district"
                        item-value="district_code"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        @mouseenter="getfocus('selectdistrict')"
                        @mouseleave="mouseOver('selectdistrict')"
                        @change="selectSubDistrictFunc()"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span class="error-text-input" v-if="!managerDetail.city">
                    {{
                    $t("Alert.pleasefilldata")
                    }}
                  </span>
                </div>

                <div class="form-group">
                  <label class="light">
                    {{
                    $t("DetailStep1.title_postcode")
                    }}
                  </label>
                  <input
                    id="managerpostcode"
                    type="text"
                    class="form-control"
                    name
                    placeholder
                    v-model="managerDetail.postcode"
                  />
                  <span
                    class="error-text-input"
                    v-if="!managerDetail.postcode"
                  >{{ $t("Alert.pleasefilldata") }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-buttons">
            <button
              type="button"
              class="btn gl-bt hover_btn"
              style="text-transform: capitalize !important"
              :disabled="!cangonextstep"
              @click="updateManagerDetail()"
            >
              <span>{{ $t("DetailStep3.btn_next") }}</span>
            </button>
          </div>
          <!-- </div> -->
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a :href="pathrightphoto" class="img-exemple iphone-model" data-fancybox>
              <img
                :src="pathrightphoto"
                style="
                  border-radius: 0.938rem !important;
                  object-fit: cover !important;
                "
                alt
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import allcountries from "@/constants/all-countries";
import { VueTelInput } from "vue-tel-input";
import swal from "sweetalert";

export default {
  components: {
    VueTelInput
  },
  data: () => ({
    stepdetail: 3,
    userToken: "",
    propertyID: "",
    itemsCountry: [
      {
        _id: 0,
        name: "ไทย",
        value: "th"
      }
    ],
    itemsProvince: [],
    itemsAmphoe: [],
    itemsDistrict: [],
    managerDetail: {
      accommodation_managed_by: "",
      management_company_name: "",
      alternative_name: "",
      address: "",
      unit_number: "",
      phone: "",
      telephone: "",
      email: "",
      city: "",
      amphoe: "",
      province: "",
      country: "",
      postcode: ""
    },
    itemsManagerCompany: [],
    itemsPhoneCountries: allcountries,
    parsedPlaceholder: "",
    pinPhone1: "",
    pinPhone2: "",
    dialCode1: "",
    dialCode2: "",
    cangonextstep: false,
    itemsStep: [],
    propertyData: {},
    pathrightphoto: "",
    defaultLG: "",
    samepropertyaddress: false,
    dataFromProperty: {},
    sameAddress: false,
    value_element: ""
  }),
  created() {
    const self = this;
    self.propertyID = self.$route.params.propertyId;
    self.userToken = self.$route.params.userToken;
    self.itemsManagerCompany = [
      self.$t("DetailStep3.items_manage_com1"),
      self.$t("DetailStep3.items_manage_com2")
    ];
    self.parsedPlaceholder = self.$t("SignupStep1.place_address_phone");
    self.defaultLG =
      localStorage?.getItem("ipcountry")?.toUpperCase() == "EN"
        ? "GB"
        : localStorage?.getItem("ipcountry")?.toUpperCase() || "TH";
    self.initAll();
  },
  mounted() {
    EventBus.$on("sendPropertyDetailData", this.getManagerDetail);
    EventBus.$on("refreshRightPhoto", this.initAll);
    this.colorHover();
  },
  watch: {
    managerDetail() {
      const self = this;
      self.initSetData();
    },
    "managerDetail.accommodation_managed_by"() {
      const self = this;
      self.initSetData();
    },
    "managerDetail.management_company_name"() {
      const self = this;
      self.initSetData();
    },
    "managerDetail.address"() {
      const self = this;
      self.initSetData();
    },
    "managerDetail.phone"() {
      const self = this;
      self.initSetData();
    },
    "managerDetail.telephone"() {
      const self = this;
      self.initSetData();
    },
    "managerDetail.email"() {
      const self = this;
      self.initSetData();
    },
    "managerDetail.city"() {
      const self = this;
      self.initSetData();
    },
    "managerDetail.country"() {
      const self = this;
      self.initSetData();
    },
    "managerDetail.postcode"() {
      const self = this;
      self.initSetData();
    }
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", e => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    initAll() {
      const self = this;
      self.initStepData();
      self.initCountry();
    },
    async initCountry() {
      const self = this;
      EventBus.$emit("loadingtillend");
      var tempCountry = [];
      var temp = [];
      self.loadingCountry = true;
      self.loadingProvince = true;
      self.itemsCountry = [];
      self.itemsProvince = [];
      self.itemsAmphoe = [];
      self.itemsDistrict = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/setting/country`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            res?.data?.result?.map(el => {
              tempCountry.push({
                _id: el._id || 0,
                name: el.value || "ไทย",
                value: el._id || "th",
                province: el.province || []
              });
              if (el?.province?.length) {
                el?.province?.map(ele => {
                  temp.push({
                    district: ele?.subdistrict || "",
                    amphoe: ele?.district || "",
                    province: ele?.province || "",
                    zipcode: ele?.zipcode || "",
                    district_code: ele?.subdistrict_code || "",
                    amphoe_code: ele?.district_code || "",
                    province_code: ele?.province_code || ""
                  });
                });
              }
            });
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.loadingCountry = false;
        self.loadingProvince = false;
        self.itemsCountry = tempCountry;
        self.itemsProvince = temp;
        self.itemsAmphoe = temp;
        self.itemsDistrict = temp;
        self.initManagerData();
      }
    },
    selectProvinceFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      self.loadingAmphoe = true;
      self.itemsAmphoe = [];
      var temp = [];

      self?.itemsProvince?.map(el => {
        if (
          el?.province_code == self?.managerDetail?.province &&
          self?.managerDetail?.province &&
          self?.managerDetail?.province !== undefined
        ) {
          temp.push(el);
        }
      });

      EventBus.$emit("endloading");
      self.loadingAmphoe = false;
      self.itemsAmphoe = temp;
    },
    selectAmphoeFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      self.loadingDistrict = true;
      var temp = [];
      self?.itemsAmphoe?.map(el => {
        if (
          el.province_code === self?.managerDetail?.province &&
          el.amphoe_code === self?.managerDetail?.amphoe &&
          self?.managerDetail?.province &&
          self?.managerDetail?.amphoe
        ) {
          temp.push(el);
        }
      });
      EventBus.$emit("endloading");
      self.loadingDistrict = false;
      self.itemsDistrict = temp;
    },
    selectSubDistrictFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      var temp = self?.itemsAmphoe?.find(
        p =>
          p?.province_code === self?.managerDetail?.province &&
          p.amphoe_code === self?.managerDetail?.amphoe &&
          p.district_code === self?.managerDetail?.city &&
          self?.managerDetail?.province &&
          self?.managerDetail?.amphoe &&
          self?.managerDetail?.city
      );
      EventBus.$emit("endloading");
      // console.log("temp: ", temp);
      if (temp?.zipcode) self.managerDetail.postcode = temp?.zipcode || "";
    },
    async initStepData() {
      const self = this;
      self.itemsStep = [];
      var temp = {};
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          // console.log(res?.data?.result[0])
          res?.data?.result?.map(el => {
            if (el._id == self.propertyID) temp = el;
          });
          self.initDataStep = temp;
          self.keepUserID = res?.data?.result[0]?.user?._id;
          if (res?.data?.result?.length) {
            self.itemsStep = [
              {
                name: self.$t("SignupStep.stepdetail1"),
                active: (self.stepdetail == 1 && true) || false,
                finish: res?.data?.result[0]?.property_detail?.success || false,
                linkItems: {
                  link: `signup/propertydetail/${self.propertyID}`,
                  name: "SignupPropertyDetailScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 1
              },
              {
                name: self.$t("SignupStep.stepdetail2"),
                active: (self.stepdetail == 2 && true) || false,
                finish: res?.data?.result[0]?.owner_detail?.success || false,
                linkItems: {
                  link: `signup/ownerdetail/${self.propertyID}`,
                  name: "SignupOwnerDetailScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 2
              },
              {
                name: self.$t("SignupStep.stepdetail3"),
                active: (self.stepdetail == 3 && true) || false,
                finish: res?.data?.result[0]?.manager_detail?.success || false,
                linkItems: {
                  link: `signup/managerdetail/${self.propertyID}`,
                  name: "SignupManagerDetailScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 3
              },
              {
                name: self.$t("SignupStep.stepdetail4"),
                active: (self.stepdetail == 4 && true) || false,
                finish: self.stepdetail == 5,
                linkItems: {
                  link: `signup/confirmation/${self.propertyID}`,
                  name: "SignupConfirmationScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 4
              }
            ];
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.getStepData(self.itemsStep);
      }
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
    },
    changePhotoRight() {
      const self = this;
      self.pathrightphoto = `${process.env.VUE_APP_PIC_API}/src/aigent_signup_bg.jpg`;
    },
    initSetData() {
      const self = this;
      if (
        self.managerDetail.accommodation_managed_by &&
        self.managerDetail.management_company_name &&
        self.managerDetail.address &&
        self.managerDetail.phone &&
        self.managerDetail.email &&
        self.managerDetail.city &&
        self.managerDetail.amphoe &&
        self.managerDetail.province &&
        self.managerDetail.country &&
        self.managerDetail.postcode
      ) {
        self.cangonextstep = true;
      } else {
        self.cangonextstep = false;

        var elementManagedBy = document.getElementById(`managercompany`);
        var elementManageName = document.getElementById(
          `managementcompanyname`
        );
        var elementManageAddress = document.getElementById(`manageraddress`);
        var elementManagePhone = document.getElementById(`contactphone`);
        var elementManageEmail = document.getElementById(`manageremail`);
        var elementManageCountry = document.getElementById(`selectcountry`);
        var elementManageProvince = document.getElementById(`selectprovince`);
        var elementManageAmphoe = document.getElementById(`selectamphoe`);
        var elementManageCity = document.getElementById(`selectdistrict`);
        var elementManagePostcode = document.getElementById(`managerpostcode`);

        if (!self.managerDetail.accommodation_managed_by) {
          elementManagedBy.classList.add("border-pink-focus");
        } else {
          elementManagedBy?.classList?.remove("border-pink-focus");
        }
        if (!self.managerDetail.management_company_name) {
          elementManageName.classList.add("border-pink-focus");
        } else {
          elementManageName?.classList?.remove("border-pink-focus");
        }
        if (!self.managerDetail.address) {
          elementManageAddress.classList.add("border-pink-focus");
        } else {
          elementManageAddress?.classList?.remove("border-pink-focus");
        }
        if (!self.managerDetail.phone) {
          elementManagePhone.classList.add("border-pink-focus");
        } else {
          elementManagePhone?.classList?.remove("border-pink-focus");
        }
        if (!self.managerDetail.email) {
          elementManageEmail.classList.add("border-pink-focus");
        } else {
          elementManageEmail?.classList?.remove("border-pink-focus");
        }
        if (!self.managerDetail.country) {
          elementManageCountry.classList.add("border-pink-focus");
        } else {
          elementManageCountry?.classList?.remove("border-pink-focus");
        }
        if (!self.managerDetail.province) {
          elementManageProvince.classList.add("border-pink-focus");
        } else {
          elementManageProvince?.classList?.remove("border-pink-focus");
        }
        if (!self.managerDetail.amphoe) {
          elementManageAmphoe.classList.add("border-pink-focus");
        } else {
          elementManageAmphoe?.classList?.remove("border-pink-focus");
        }
        if (!self.managerDetail.city) {
          elementManageCity.classList.add("border-pink-focus");
        } else {
          elementManageCity?.classList?.remove("border-pink-focus");
        }
        if (!self.managerDetail.postcode) {
          elementManagePostcode.classList.add("border-pink-focus");
        } else {
          elementManagePostcode?.classList?.remove("border-pink-focus");
        }
      }
    },
    changeTelCT1(obj) {
      const self = this;
      self.dialCode1 = obj.dialCode;
      self.pinPhone1 = obj.iso2 || "TH";
    },
    changeTelCT2(obj) {
      const self = this;
      self.dialCode2 = obj.dialCode;
      self.pinPhone2 = obj.iso2 || "TH";
    },
    async initManagerData() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          temp = res.data.result;
          self.propertyData = res.data.result[0];
          self.pinPhone1 =
            temp?.contact?.phones[0]?.country_code || self?.defaultLG || "th";
          self.pinPhone2 =
            temp?.contact?.phones[1]?.country_code || self?.defaultLG || "th";
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.getManagerDetail(temp);
      }
    },
    getManagerDetail(data) {
      const self = this;
      EventBus.$emit("loadingtillend");
      const tempManagerCountry = self?.itemsCountry?.find(
        p =>
          p.name === data[0]?.manager_detail?.country ||
          p.name === data[0]?.contact?.country
      );
      const tempManagerProvince = self?.itemsProvince?.find(p =>
        data[0]?.manager_detail?.province
          ? p.province === data[0]?.manager_detail?.province
          : p.province === data[0]?.contact?.province
      );
      const tempManagerAmphoe = self?.itemsAmphoe?.find(p =>
        data[0]?.manager_detail?.district
          ? p.amphoe === data[0]?.manager_detail?.district
          : p.amphoe === data[0]?.contact?.district
      );
      var tempItemsAmphoe = [];
      var tempItemsCity = [];
      var tempManagerCity = null;
      self?.itemsProvince?.map(el => {
        if (data[0]?.manager_detail?.province) {
          if (data[0]?.manager_detail?.district) {
            if (el.province == data[0]?.manager_detail?.province) {
              tempItemsAmphoe.push(el);
            }
            if (
              el.province == data[0]?.manager_detail?.province &&
              el.amphoe == data[0]?.manager_detail?.district
            ) {
              tempItemsCity.push(el);
              if (el.district == data[0]?.manager_detail?.city)
                tempManagerCity = el;
            }
          }
        } else {
          if (el.province == data[0]?.contact?.province) {
            tempItemsAmphoe.push(el);
          }
          if (
            el.province == data[0]?.contact?.province &&
            el.amphoe == data[0]?.contact?.district
          ) {
            tempItemsCity.push(el);
            if (el.district == data[0]?.contact?.subdistrict)
              tempManagerCity = el;
          }
        }
      });
      self.itemsAmphoe = tempItemsAmphoe;
      self.itemsDistrict = tempItemsCity;
      // console.log(data[0]?.manager_detail);
      if (data[0]?.manager_detail?.success) {
        self.managerDetail = {
          accommodation_managed_by:
            data[0]?.manager_detail?.accommodation_managed_by || "",
          management_company_name:
            data[0]?.manager_detail?.management_company_name || "",
          alternative_name: data[0]?.manager_detail?.alternative_name || "",
          address: data[0]?.manager_detail?.address || "",
          unit_number: data[0]?.manager_detail?.unit_number || "",
          phone: data[0]?.manager_detail?.phone || "",
          telephone: data[0]?.manager_detail?.telephone || "",
          email: data[0]?.manager_detail?.email || "",
          city:
            tempManagerCity?.district_code ||
            data[0]?.manager_detail?.city ||
            "",
          amphoe:
            tempManagerAmphoe?.amphoe_code ||
            data[0]?.manager_detail?.district ||
            "",
          province:
            tempManagerProvince?.province_code ||
            data[0]?.manager_detail?.province ||
            "",
          country:
            tempManagerCountry?.value || data[0]?.manager_detail?.country || "",
          postcode:
            tempManagerCity?.zipcode || data[0]?.manager_detail?.postcode || ""
        };
      } else if (data[0]?.contact) {
        self.managerDetail = {
          accommodation_managed_by: "",
          management_company_name: data[0]?.contact?.name || "",
          alternative_name: data[0]?.owner_detail?.alternative_name || "",
          address: data[0]?.contact?.address_line_1 || "",
          unit_number: data[0]?.contact?.address_line_2 || "",
          phone: data[0]?.contact?.phones[0]?.phone_number || "",
          telephone: data[0]?.contact?.phones[1]?.phone_number || "",
          email: data[0]?.user?.email || "",
          city:
            tempManagerCity?.district_code ||
            data[0]?.contact?.subdistrict ||
            "",
          amphoe:
            tempManagerAmphoe?.amphoe_code ||
            data[0]?.manager_detail?.district ||
            "",
          province:
            tempManagerProvince?.province_code ||
            data[0]?.manager_detail?.province ||
            "",
          country: tempManagerCountry?.value || data[0]?.contact?.country || "",
          postcode:
            tempManagerCity?.zipcode || data[0]?.contact?.post_code || ""
        };
      }

      EventBus.$emit("endloading");
    },
    getPropertyDetail() {
      const self = this;
      const data = self.propertyData;
      // console.log("data: ", data);
      EventBus.$emit("loadingtillend");
      const tempPropertyCountry = self?.itemsCountry?.find(
        p =>
          p.name === data?.property_detail?.country ||
          p.name === data?.contact?.country
      );
      const tempPropertyProvince = self?.itemsProvince?.find(p =>
        data?.property_detail?.province
          ? p.province === data?.property_detail?.province
          : p.province === data?.contact?.province
      );
      const tempPropertyAmphoe = self?.itemsAmphoe?.find(p =>
        data?.property_detail?.district
          ? p.amphoe === data?.property_detail?.district
          : p.amphoe === data?.contact?.district
      );
      var tempItemsAmphoe = [];
      var tempItemsCity = [];
      var tempPropertyCity = null;
      self?.itemsProvince?.map(el => {
        if (data?.property_detail?.province) {
          if (data?.property_detail?.district) {
            if (el.province == data?.property_detail?.province) {
              tempItemsAmphoe.push(el);
            }
            if (
              el.province == data?.property_detail?.province &&
              el.amphoe == data?.property_detail?.district
            ) {
              tempItemsCity.push(el);
              if (el.district == data?.property_detail?.city)
                tempPropertyCity = el;
            }
          }
        } else {
          if (el.province == data?.contact?.province) {
            tempItemsAmphoe.push(el);
          }
          if (
            el.province == data?.contact?.province &&
            el.amphoe == data?.contact?.district
          ) {
            tempItemsCity.push(el);
            if (el.district == data?.contact?.subdistrict)
              tempPropertyCity = el;
          }
        }
      });
      self.itemsAmphoe = tempItemsAmphoe;
      self.itemsDistrict = tempItemsCity;
      self.managerDetail.postcode =
        tempPropertyCity?.zipcode || data?.property_detail?.postcode || "";
      self.managerDetail.city =
        tempPropertyCity?.district_code || data?.property_detail?.city || "";
      self.managerDetail.amphoe =
        tempPropertyAmphoe?.amphoe_code || data?.contact?.district || "";
      self.managerDetail.province =
        tempPropertyProvince?.province_code || data?.contact?.province || "";
      self.managerDetail.country =
        tempPropertyCountry?.value || data?.property_detail?.country || "";

      EventBus.$emit("endloading");
    },
    sameAddressFunc() {
      const self = this;
      self.samepropertyaddress = true;
      self.getPropertyDetail();
    },
    clearSameAddress() {
      const self = this;
      self.samepropertyaddress = false;
      self.managerDetail.city = "";
      self.managerDetail.amphoe = "";
      self.managerDetail.province = "";
      self.managerDetail.country = "";
      self.managerDetail.postcode = "";
    },
    updateManagerDetail() {
      const self = this;
      const obj = {
        accommodation_managed_by:
          self?.managerDetail?.accommodation_managed_by || "",
        management_company_name: self?.managerDetail?.management_company_name,
        alternative_name: self?.managerDetail?.alternative_name || "",
        address: self?.managerDetail?.address || "",
        unit_number: self?.managerDetail?.unit_number || "",
        phone: self?.managerDetail?.phone || "",
        telephone: self?.managerDetail?.telephone || "",
        email: self?.managerDetail?.email || "",
        city: self?.managerDetail?.city.toString() || "",
        district: self?.managerDetail?.amphoe.toString() || "",
        province: self?.managerDetail?.province.toString() || "",
        country: self?.managerDetail?.country || "",
        postcode: (self?.managerDetail?.postcode).toString() || ""
      };
      // console.log("obj: ", obj);
      if (
        !obj.accommodation_managed_by ||
        !obj.management_company_name ||
        !obj.address ||
        !obj.phone ||
        !obj.email ||
        !obj.city ||
        !obj.district ||
        !obj.province ||
        !obj.country ||
        !obj.postcode
      ) {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
      } else {
        self.updateManagerFunc(obj);
      }
    },
    async updateManagerFunc(obj) {
      const self = this;
      EventBus.$emit("loadingPage");
      var item = {
        link: `signup/confirmation/${self.propertyID}`,
        name: "SignupConfirmationScreen",
        params: { propertyId: self.propertyID }
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/manager_detail?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          self.changeStep(item);
          EventBus.$emit("changeDetailStep", 4);
        } else {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      }
    },
    changeStep(linkItems) {
      EventBus.$emit("changePathname", linkItems);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  border-color: var(--pink) !important;
}

.btn.btn-searchmap {
  background-color: var(--pink) !important;
  border-color: var(--pink) !important;
}

.btn.btn-searchmap:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn-cancel-searchmap:hover {
  background-color: #777 !important;
  border-color: #777 !important;
}

.btn-cancel-searchmap:hover span {
  color: #fff !important;
}
</style>

<style>
.vti__dropdown-list {
  z-index: 999 !important;
  background-color: #fff !important;
}

.vti__dropdown {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -ms-flex-line-pack: center !important;
  align-content: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  position: relative !important;
  padding: 0 7px !important;
  cursor: pointer !important;
}

.vti__dropdown:hover {
  background-color: transparent !important;
}

.vti__dropdown.open {
  background-color: transparent !important;
}

.vti__input {
  border: none !important;
  border-radius: 0 2px 2px 0 !important;
  width: 100% !important;
  outline: none !important;
  padding-left: 7px !important;
}

.vue-tel-input.disabled .dropdown,
.vue-tel-input.disabled .selection,
.vue-tel-input.disabled input {
  cursor: no-drop !important;
}

.vue-tel-input:focus-within {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #e83e8c !important;
}

.vue-tel-input {
  display: flex !important;
  align-items: center !important;
  justify-content: left !important;
}
</style>
